.automation-banner-container {
    display:flex;
    flex-direction: row;
    height: 490px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    padding: 0.5rem;
    background-image: url('fabylab-automation.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.automation-banner-text {
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: blur(1rem);
    padding: 1rem;
    border-radius: 1rem;
    height:max-content;
}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {

}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {

}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .automation-banner-text {
        margin-left: calc(100vw / 6);
    }


}
