.marketingSales-banner-container {
    position: relative;
    display:flex;
    flex-direction: row;
    height: 490px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    padding: 1rem;
    background-image: url('fabylab-background.svg');
    overflow-x: hidden;
    direction: rtl;
}

.marketingSales-banner-text {
    position: absolute;
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: blur(1rem);
    padding: 1rem;
    border-radius: 1rem;
    height:max-content;
    z-index: 2;
}

.marketingSales-banner-image {
    position: relative;
    margin-top: 80px;
    margin-left: auto;
}

#rocket {
    position:relative;
    animation-name: rocket;
    animation-duration: 4s;
}

#rocketPath {
    animation-name: fadein;
    animation-duration: 3s;
}

#chart1 {
    animation-name: animatebottom;
    animation-duration: 1s;
}

#chart2 {
    animation-name: animatebottom;
    animation-duration: 1.5s;
}

#chart3 {
    animation-name: animatebottom;
    animation-duration: 2s;
}

#chart4 {
    animation-name: animatebottom;
    animation-duration: 2.5s;
}

#chart5 {
    animation-name: animatebottom;
    animation-duration: 3s;
}

#humanStairs {
    animation-name: fadein;
    animation-duration: 3s;
}

#coin1, #coin2, #coin3, #coin4, #coin5, #coin6  {
    animation-name: fadein;
    animation-duration: 3s;
}

#lead1, #lead2, #lead3, #lead4, #lead5, #lead6, #lead7  {
    animation-name: animateup;
    animation-duration: 3s;
}



@keyframes rocket { 
    0%       { transform: translatex(-300px) translatey(300px) }
    100%     { transform: translatex(0px) translatey(0px) }
}

@keyframes fadein { 
    0% {opacity:0;}
    90% {opacity:0;}
    100% {opacity:1;}
}

@keyframes animatebottom { 
    0%       { transform: translatey(300px) }
    100%     { transform: translatey(0px) }
}

@keyframes animateup{ 
    0%       { transform: translatey(-300px) }
    100%     { transform: translatey(0px) }
}




 /* PHONES SCREEN (4 COLUMNS) */
 @media (min-width: 0px) and (max-width: 640px){
    .marketingSales-banner-text {
        margin-left: 1rem;
    }
    .marketingSales-header {
        font-size: 2rem;
    }
    .marketingSales-text {
        font-size: 1rem;
    }
    .marketingSales-banner-image {
        height: 300px;
        width: 60%;
    }
}

 /* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){
    .marketingSales-banner-text {
        left: 1rem;
    }

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .marketingSales-banner-text {
        left: 1rem;
    }
}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .marketingSales-banner-image {
        margin-right: calc(100vw / 6);
        margin-top: 80px;
    }
    .marketingSales-banner-text {
        left: 1rem;
        margin-left: calc(100vw / 6);
    }


}