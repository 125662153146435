.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 250;
}

@media (min-width: 768px) {
    .backdrop {
        display: none;
    }

}