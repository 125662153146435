/* CORE CSS */

.page-container {
  display: grid;
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 71px auto 71px;
  grid-template-areas: 
  "nav"
  "main"
  "footer";
}

.page-header {
  grid-area: nav;
  max-width: 100%;
  min-width: 100%;
  position: fixed;
  z-index: 200;
}

.page-content {
  grid-area: main;
  max-width: 100%;
  min-width: 100%;
}

.page-footer {
  grid-area: footer;
  max-width: 100%;
  min-width: 100%;
  min-height: 71px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e9eab;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 10px;
}

/* Legend to test responsive screen in different resolutions */
.display-resolution {
  display: flex;
  position: fixed;
  z-index: 10000;
  font-weight: bold;
  color: white;
}

/* VERY NARROM SCREEN (HIDE CONTENT) */
@media (min-width: 0px) and (max-width: 250px) {
  .display-resolution-phone,
  .display-resolution-tablet,
  .display-resolution-laptop,
  .display-resolution-large,
  .page-header,
  .page-content,
  .page-footer,
  .side-drawer {
    display: none;
  }
}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 251px) and (max-width: 640px) {
  .display-resolution-tablet,
  .display-resolution-laptop,
  .display-resolution-large  {
    display: none;
  }

}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px) {
  .display-resolution-phone,
  .display-resolution-laptop,
  .display-resolution-large {
    display: none;
  }

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
  .display-resolution-phone,
  .display-resolution-tablet,
  .display-resolution-large {
    display: none;
  }
}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
  .display-resolution-phone,
  .display-resolution-tablet,
  .display-resolution-laptop {
    display: none;
  }

}