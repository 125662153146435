.skeleton-container {
    margin: 1rem;
}

.skeleton-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
    color: lightgray;
    background-color:lightgray;
    border-radius: 10px !important;
    top:4%;
    left: 1rem;
    height: 30px;
    width: 40vw;
    overflow-x: hidden;
}

.card-skeleton-container {
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 400px;
    align-content: center;
    justify-content: center;
    grid-gap: 1em;
    grid-auto-flow: dense;
    margin-bottom: 1rem;
}

.ghost-container {
    background-color:#FFF;
    border-radius: 10px !important;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);

}

.ghost-image {
    background-color:lightgray;
    border-radius: 10px !important;
    width: 100%;
    height: 70%;
    overflow-x: hidden;
}

.ghost-line1 {
    position: relative;
    background-color:lightgray;
    border-radius: 10px !important;
    top:4%;
    left: 1rem;
    height: 10%;
    width: 80%;
    overflow-x: hidden;
}

.ghost-line2 {
    position: relative;
    background-color:lightgray;
    border-radius: 10px !important;
    top:8%;
    left: 1rem;
    height: 10%;
    width: 60%;
    overflow-x: hidden;
}

.ghost-image::before {
    content: '';
    display: block;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 350px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

}

@keyframes load {
    from {
        left: 0;
    }
    to   {
        left: 100%;
    }
}

.profile-spinner{
    color: orange;
}

.spinner-wrapper {
    position: absolute;
    vertical-align: middle;
    left: 55%;
    top: 40%;
    margin-left: -4em;
}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .card-skeleton-container {
        grid-template-columns: repeat(auto-fit, 90vw);
        grid-auto-rows: 90vw;
        
    }
}