.chart-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;


}

.table-chart {
    font-size: 0.8rem;
    font-weight: 400;
    color: #212529;
}

.chart-title {
    text-align: center;
    font-weight: 600;
    color: #212529;
    font-size: 0.8rem;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.chart-container table {
    table-layout: fixed;
    width: 500px;
    border: 1px solid orange;

}

.table-chart th {
    background-color: rgb(255, 167, 4, 0.6);
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    border: 1px solid orange;
}
  
.table-chart td {
    border: 1px solid gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 15px;
    border: 1px solid lightgrey;
}

.table-chart th, td {
    width: 50px;
    overflow: hidden;
}

.table-chart tr th:first-child,
.table-chart tr th:last-child,
.table-chart tr td:last-child,
.table-chart tr td:first-child {
  width: 0.7rem;
  min-width: 0.7rem;
  max-width: 0.7rem;
  word-break: break-all;
  text-align: center;

}


/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .chart-container table {
        table-layout: fixed;
        width: 90vw;
        border: 1px solid black;
    }
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){


}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {


}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {


}