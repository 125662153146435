.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 300;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);

}

.sidedrawer-logo {
    width: 30%;
    margin-left: calc(35%);
}

.side-drawer ul {
    /* height: 100%; */
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
}

.side-drawer li {
    margin: 0.5rem;
    width: 80px;

}

.nav-link {
    padding: 0 !important;
}

.side-drawer a {
    color: rgb(104, 125, 136);
    text-decoration: none;
}

.drawer-contact a {
    color: white !important;
    font-weight: bold !important;
    margin-left: 0.5rem ;
    
}

.drawer-contacts {
    background-color: orange !important;
    background: 100% 100%;
    border-radius: 10px;
    margin-left: -0.5rem;
    /* margin-right: -0.5rem; */
    max-width: 90%;

}

@media (max-width: 1099px) {
    .language-container-small.open {
        position: fixed;
        margin-left: 40px;
        bottom: 2%;
        font-size: 1.2rem;

    }

}

@media (min-width: 1100px) {
    .side-drawer {
        display: none;
    }

    .language-container-small {
        display: none;
    }
}