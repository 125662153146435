.login-container {
    display:flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: purple;
}

.login-background-container {
    background-image: url('login-background.svg');
    height: 100%;
    width: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;

}

.login-form-container {
    background-color: white;
    min-width: 200px;
    width: 35%;
    height: 100%;
    padding: 1rem;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.profile-spinner{
    color: orange;
}

.validation-error {
    color: red;
    font-size: medium;
}

.form-title {
    position: relative;
    text-align: center;
    width: auto;
    font-size: xx-large;
    color: black;
    
}

.spinner-wrapper {
    position: absolute;
    vertical-align: middle;
    left: 55%;
    top: 40%;
    margin-left: -4em;
}

.profile-display-name {
    font-size: x-large;
}

.profile-image-wraper {
    position: relative;
    background-color: orange;
    vertical-align: middle;
    align-content: center;
    margin-left: 25%;
    margin-top: 10%;
    width: 50%;
    height: auto;
    border-radius: 50%;
    z-index: 1;
  }

.profile-image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}

.profile-image-text {
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-size: 2.5rem;
    color: orange;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.profile-image:hover + .profile-image-text{
    display: block;
    cursor: pointer;

}

.profile-image-text:hover{
    display: block;
    color: black;
    cursor: pointer;

}

.edit {
    color:blue;
}

.edit:hover {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
}


/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {
    .login-container {
        display:relative;
        background-image: url('login-background.svg');
        height: 100%;
        width: 100%;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;

    }

    .login-background-container { 
        display:none;
    }


    .login-form-container { 
        position: relative;
        width: 400px;
        height: 400px;
        opacity: 0.9;
        border-radius: 2rem;
        background-color: white;
        backdrop-filter: blur(2rem);
        margin: 1rem;

    }
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){
    .login-form-container {
        width: 50%;
    }
}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {


}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {



}