  
.logo svg {
width: 100%;
height: 100%;

}

.bubble1 {
animation: bubbles1 5s linear infinite;
animation-delay: 0.5s;
transform-origin: center;
transform-box: fill-box;

}

.bubble2 {
    animation: bubbles2 5s linear infinite;
    animation-delay: 0.6s;
    transform-origin: center;
    transform-box: fill-box;
    
}


.bubble3 {
    animation: bubbles1 5s linear infinite;
    animation-delay: 1s;
    transform-origin: center;
    transform-box: fill-box;
    
}

.bubble4 {
    animation: bubbles2 5s linear infinite;
    animation-delay: 1.3s;
    transform-origin: center;
    transform-box: fill-box;
    
}

.bubble5 {
    animation: bubbles1 5s linear infinite;
    animation-delay: 2s;
    transform-origin: center;
    transform-box: fill-box;
    
}

.bubble6 {
    animation: bubbles2 5s linear infinite;
    animation-delay: 2.3s;
    transform-origin: center;
    transform-box: fill-box;
    
}

.bubble7 {
    animation: bubbles1 5s linear infinite;
    animation-delay: 3s;
    transform-origin: center;
    transform-box: fill-box;
    
}

.bubble8 {
    animation: bubbles2 5s linear infinite;
    animation-delay: 4s;
    transform-origin: center;
    transform-box: fill-box;
    
}

@keyframes  bubbles1 {
    0% {
        transform: translate(-1px, 250px);
    }
    25% {
        transform: translate(11px, 200px);

    }
    50% {
        transform: translate(-1px, 150px);

    }
    70% {
        transform: translate(11px, 100px);

    }
    90% {
        transform: translate(-1px, 50px);

    }
    100% {
        transform: translate(11px, 0px);
    }


}



@keyframes  bubbles2 {
    0% {
        transform: translate(-20px, 250px);
    }
    25% {
        transform: translate(7px, 200px);

    }
    50% {
        transform: translate(0px, 150px);

    }
    70% {
        transform: translate(7px, 100px);

    }
    90% {
        transform: translate(-20px, 50px);

    }
    100% {
        transform: translate(0px, 0px);
    }


}