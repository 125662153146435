.toolbar-container {
    width: 100%;
    max-width: 100%;
    display: grid;
    min-height: 71px;
    height: 100%;
    background: black;
    box-shadow: 0 4px 2px -2px rgb(199, 199, 199);
    z-index: 200;

}

.toolbar-items {
    display: flex;
    grid-area: nav;
    flex-wrap: wrap;
    align-items: center;
}

.toolbar-items > div:nth-child(1),
.toolbar-items > div:nth-child(3),
.toolbar-items > div:nth-child(4),
.toolbar-items > div:nth-child(5),
.toolbar-items > div:nth-child(6),
.toolbar-items > div:nth-child(7),
.toolbar-items > div:nth-child(8),
.toolbar-items > div:nth-child(9),
.toolbar-items > div:nth-child(10) {
    margin-right: 1em;
}

.toolbar-logo-image {
    width: 30px;
    top: 0;
    margin-top: -1.5%;
    margin-left: 1rem;
    animation:  swing 2s ease-in-out;
    transform-origin: center;
    transform-box: fill-box;

}

.hide-component {
    display: none;
}

.signedIn {
    display: true;
}

.signedOut {
    display: true;
}

.toolbar-logo-text-item,
.toolbar-logo-lab  {
    text-decoration: none;
    font-size: 2rem;
    cursor: pointer;
}

.toolbar-logo-text-item {
    color: white;
}

.toolbar-logo-text-item:hover {
    text-decoration: none;
    color: white;
}




.toolbar-items a:hover,
.toolbar-items a:active {
    color:orange;
}

.nav-link {
    padding: 0 !important;
}

.toolbar-items a,
.dropdown-nav.item {
    color: white;
    text-decoration: none;
    
}

.dropdown-items a {
    display: grid;
    align-content: center;
    justify-content: left;
    padding: 0 1rem;
    min-height: 40px;
    min-width: 150px;
    color:black;
    width: 100%;
    
} 

.toolbar-items a:hover,
.toolbar-items a:active {
    color:orange;
}

.dropdown-items a:hover,
.dropdown-items a:active,
.dropdown-items-sign a:hover,
.dropdown-items-sign a:active {
    background-color: orange;
    color: white;
    text-decoration-color: transparent;
}

.dropdown-menu.show {
    transform: translate(0, 22px) !important;
    padding: 0 !important;
    min-width: 100% !important;
    height: auto !important;
    
  }

.dropdown-items-sign {
    display: flex;

}

.toolbar-logo-text-item a:hover {
    text-decoration: none;
    text-decoration-style: none;
    color:white;
}

.toolbar-logo-lab a {
    color: orange;
    text-decoration: none;
    font-size: 2rem;
}

.toolbar-logo-lab a:hover {
    color: orange;
    text-decoration: none;
    font-size: 2rem;
}

@keyframes  swing {
    0% {
        transform: rotateZ(0deg);
    }
    30% {
        transform: rotateZ(-30deg);

    }
    60% {
        transform: rotateZ(30deg);

    }
    100% {
        transform: rotateZ(0deg)
    }


}


/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {
    .toolbar-container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 
        "nav nav nav nav";
    }

    .toolbar-items > div:nth-child(4) {
        margin-left: auto;
    }

    .toolbar-logo-lab {
        margin-right: 0.5rem !important;
    }
    
    
    .toolbar-item,
    .toolbar-item,
    .toolbar-item,
    .toolbar-item,
    .toolbar-item {
        display: none;
    }

}
  
/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px) {
    .toolbar-container {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: 
        "nav  nav  nav nav nav nav nav nav";
    }

    .toolbar-items > div:nth-child(4) {
        margin-left: auto;
    }
    
    .toolbar-item,
    .toolbar-item,
    .toolbar-item,
    .toolbar-item,
    .toolbar-item {
        display: none;
    }
}
  
/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .toolbar-container {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: 
        "nav nav nav nav nav nav nav nav";
    }

    .toggle-button {
        display: none
    }

    .toolbar-items > div:nth-child(5) {
        margin-left: auto;
    }

    .phone-contacts,
    .email-contacts,
    .toggle-button {
        display: none;
    }
}
  
/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .toolbar-container {
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: 
        ".  .  nav nav nav nav nav nav nav nav . .";
    }

    .toolbar-items > div:nth-child(5) {
        margin-left: auto;
    }

    .toggle-button {
        display: none
    }
    


}