.ai-container {
    position: relative;
}

.ai-banner-container {
    display:flex;
    flex-direction: row;
    height: 490px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    padding: 1rem;
    background-image: url('fabylab-background.svg');
}

.ai-banner-image-container {
    height: 100%;
    width: 50%;
}

.ai-banner-text {
    display: grid;
    justify-content: center;
    align-content: center;

}

.ai-banner-image {
    background-image: url('fabylab-ai.svg');
    height: 90%;
    width: 90%;
    margin-left: 5%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    display: grid;
    justify-content: center;
    align-content: center;
}

.ai-banner-content {
    width: 50%;
    height: 100%;
    padding: 1rem;
    display: grid;
    align-content: center;
    justify-content: center;
}

.ai-banner-content-video {
    border-radius: 2rem;
    height: calc(40vw * 0.5625);
    width: 40vw;
    max-height: 400px;
    max-width: 711px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}


/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {
    .ai-banner-container {
        display:block;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }

    .ai-banner-image-container {
        display:block;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .ai-banner-text h1{
        font-size: 2rem;
    }

    .ai-banner-image {
        height: 80vw;
        width: 80vw;

    }

    .ai-banner-image h1 {
        /* display: none; */
        font-size: 1.8rem;
        /* align-self: end; */
        color: black;
    }

    .ai-banner-content {
        width: 100%;
        height: 100%;
    }

    .ai-banner-content-video {
        border-radius: 2rem;
        height: calc(90vw * 0.5625);
        width: 90vw;
        max-height: 400px;
        max-width: 711px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){
    .ai-banner-container {
        display:flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }

    .ai-banner-image-container {
        height: 400px;
        width: 100%;
    }

    .ai-banner-content {
        width: 100%;
        height: 100%;
    }

    .ai-banner-content-video {
        border-radius: 2rem;
        height: calc(90vw * 0.5625);
        width: 90vw;
        max-height: 400px;
        max-width: 711px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    
    }
}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .ai-banner-container {
        display:flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }

    .ai-banner-image-container {
        height: 400px;
        width: 100%;
    }

    .ai-banner-content {
        width: 100%;
        height: 100%;
    }

    .ai-banner-content-video {
        border-radius: 2rem;
        height: calc(90vw * 0.5625);
        width: 90vw;
        max-height: 400px;
        max-width: 711px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    
    }

}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {



}