.articleCard {
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    overflow: hidden;
    cursor: pointer;

}

.articleCard:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
}

.articleCard-image {
    background-color: lightgray;
    position: relative;
    width: 100%;
    height: 70%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}

.articleCard-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-color: gray;
    opacity: 0.4;
    z-index: 3;
}

.articleCard-text-container {
    position: relative;
    background-color: white;
    width: 100%;
    height: 30%;
    z-index: 2;
    font-size: 0.7rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.articleCard-text-category {
    margin-top: 0.5rem;
    color: orange;
    background-color: white;
    font-weight: bold;
    text-transform: uppercase
}

.articleCard-text-header {
    color: black;
    font-weight: bold;
    font-size: 1rem;
    background-color: white;
}

.articleCard-text-description {
    color: black;
    font-size: 1rem;
    background-color: white;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.articleCard-text-signature {
    position: absolute;
    color: gray;
    background-color: white;
    bottom: 0;
    width: 100%;
    height: 25%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}