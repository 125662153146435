/* CORE CSS */
.projects-container {
    margin: 1rem;
}

.projects-cards-container {
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 400px;
    align-content: center;
    justify-content: center;
    grid-gap: 1em;
    grid-auto-flow: dense;
    margin-bottom: 1rem;
}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .projects-cards-container {
        grid-template-columns: repeat(auto-fit, 90vw);
        grid-auto-rows: 90vw;
        
    }
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {


}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {


}