.dashboard-update {
    font-weight: 600;
    color: #212529;
    font-size: 0.8rem;
    margin-left: 1rem;
}

.dashboard-components {
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, 500px);
    grid-auto-rows: calc(500px * 0.5625);
    align-content: center;
    justify-content: center;
    grid-gap: 1em;
    grid-auto-flow: dense;
    margin-bottom: 1rem;;
}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .dashboard-components {
        grid-template-columns: repeat(auto-fit, 90vw);
        grid-auto-rows: auto;
        
    }
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){


}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {


}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {


}