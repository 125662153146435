
.landing-banner-container {
    display: flex;
    flex-direction: row;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    width: 100%;
    min-height: 490px;
    background-color: lightgray;
    background-image: url('fabylab-homepage-desktop.svg');
    background-color: #e3eaff;
    background-repeat: no-repeat;
    background-position: right;
    
}

.landing-banner-shade {
    position: absolute;
    width: 100%;
    height: 490px;
    background-color: rgb(247, 247, 247, 0.1);
}

.landing-banner-text {
    display: grid;
    z-index: 1;
}

.landing-banner-text-title {
    grid-area: hed;
    align-self: end;
    text-transform: uppercase;
}

.landing-banner-text-description {
    grid-area: des;
}

.landing-banner-text-title h1 span{
    font-size: larger;
    font-weight: bolder;
    color: black

}

.landing-about-container {
    display: grid;
    align-content: center;
    justify-content: center;
    text-align: center;
    margin: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;

}

.landing-options-container {
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 400px;
    align-content: center;
    justify-content: center;
    grid-gap: 1em;
    grid-auto-flow: dense;
    margin-bottom: 1rem;
}

.landing-options-card-container {
    background-color: white;
}

.landing-options-card-header {
    background-color: rgba(255, 253, 253, 0.863);
    height: 15%;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
    color: gray;
}
.landing-options-card-icon {
    height: 50%;
    padding-top: 5%;
}

.landing-options-card-icon-network {
    height: 70%;
    width: 70%;
    margin-left: calc(50% - 20%);
    background-image: url('network.svg');
    background-repeat: no-repeat;

}

.landing-options-card-icon-partner {
    height: 70%;
    width: 70%;
    margin-left: calc(50% - 20%);
    background-image: url('rocket.svg');
    background-repeat: no-repeat;
}

.landing-options-card-icon-investor {
    height: 70%;
    width: 70%;
    margin-left: calc(50% - 20%);
    background-image: url('investor.svg');
    background-repeat: no-repeat;

}



.landing-options-card-description {
    background-color: white;
    height: 30%;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
}


/* VERY NARROM SCREEN (HIDE CONTENT) */
@media (min-width: 0px) and (max-width: 250px) {

}
  
/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 251px) and (max-width: 640px) {
    .landing-banner-container {
        background-image: url('fabylab-homepage-mobile.svg');
    }

    .landing-banner-shade {
        background-color: rgb(247, 247, 247, 0.1);
    }

    .landing-banner-text {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 
        "hed hed hed hed"
        "des des des des";
        padding: 1rem;
    }

    .landing-banner-text-title {
        align-self: start;

        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: blur(1rem);
        padding: 1rem;
        border-radius: 1rem;
    }

    .landing-banner-text-title h1 span{
        font-size: 2.5rem;
        font-weight: bolder;
        color: black
    
    }
    .landing-banner-text-description {
        align-self: end; 
        background-color: rgb(255, 255, 255, 0.5);
        display: none;
    }
    .landing-banner-text-description h1 {
        /* display: none; */
        font-size: 2rem;
        /* align-self: end; */
        color: black;
    }

    .landing-options-container {
        grid-template-columns: repeat(auto-fit, 90vw);
        grid-auto-rows: 90vw;
        
    }
}
  
/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px) {
    .landing-banner-text {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 
        "hed hed hed hed"
        "des des des des";
        grid-template-rows: auto 1fr;
        padding: 1rem;
    }

    .landing-banner-text-title {
        align-self: start;

        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: blur(1rem);
        padding: 1rem;
        border-radius: 1rem;
    }

    .landing-banner-text-title h1 span{
        font-size: 2.5rem;
        font-weight: bolder;
        color: black
    
    }
    .landing-banner-text-description {
        align-self: end; 
        background-color: rgb(255, 255, 255, 0.5);
        display: none;
    }
    .landing-banner-text-description h1 {
        /* display: none; */
        font-size: 2rem;
        /* align-self: end; */
        color: black;
    }

    .landing-banner-text-description {
        align-self: end; 
        background-color: rgb(255, 255, 255, 0.5);
    }


}
  
/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .landing-banner-text {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: 
        "hed hed hed hed hed . . ."
        "des des des des des . . .";
        grid-template-rows: auto 1fr;
        padding: 1rem;
    }

    .landing-banner-text-title {
        grid-area: hed;
        align-self: start;
        
    }
    .landing-banner-text-description {
        align-self: end; 
        background-color: rgb(255, 255, 255, 0.5);
    }
}
  
/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .landing-banner-text {
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: 
        ".  .  hed hed hed hed hed . . . . ."
        ".  .  des des des des des . . . . .";
    }
}