.categories-filter-container,
.projects-filter-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
}

.categories-filter-checkbox-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.categories-filter-checkbox{
    margin-right: 0.2rem;
    margin-top: 0.2rem;
}

.categories-filter-title {
    margin-right: 0.5rem;
    font-weight: bold;
}

.filter-label {
    margin-right: 0.2rem;
}

.filter-label-number {
    color: blue;
    margin-right: 0.5rem;

}

/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {

}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {

}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {



}