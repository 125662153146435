/* START THE LIST OF ARTICLES PAGE CSS  */
.articles-container {
    padding: 1rem;
}

.articles-list-container {
    padding: 1rem;
}

.articles-top-container {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    overflow: hidden;
    cursor: pointer;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgb(247, 245, 245);
}

.articles-top-container:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
}

.articles-text-description {
    width: 50%;
    padding: 1rem;
}

.articles-top-image {
    height: calc((100vw / 3) * 0.5625);
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1rem;
    padding-left: 0.5rem;

}

.articles-top-image-new {
    font-size: medium;
    font-weight: bold;
    border-radius: 1rem;
    background-color: rgb(255, 255, 255, 0.9);
    backdrop-filter: blur(1rem);
    padding: 0.5rem;
    height:max-content;
    color: orange;
    animation: blinker 1.5s infinite;
    

}

.articles-top-text-container {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 100px;
    height: 100%;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-top: 0.4rem;
}

.articles-text-header {
    color: black;
    font-weight: bold;
    font-size: 2rem;
}

.articles-text-header:hover {
    color: orange;
}

.articles-top-text-description {
    color: black;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.articles-cards-container {
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 400px;
    align-content: center;
    justify-content: center;
    grid-gap: 1em;
    grid-auto-flow: dense;
    margin-bottom: 1rem;;
}

/* START THE INDIVIDUAL ARTICLE PAGE CSS  */
.article-container {
    padding: 1rem;
}
.article-container-title {
    color: gray;
    font-size: 2rem;
    font-weight: bold;

}

.article-container-image {
    display: grid;
    position: relative;
    width: 100%;
    height: calc((100vw / 2) * 0.5625);
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 0;
}

.article-container-description {
    margin-bottom: 1rem;
    color: gray;
}

.article-container-author {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-content:flex-end;
}

.article-container-author-image {
    background-color: #bbb;
    background-image: url("https://fabylab-website-images.s3.amazonaws.com/Fabio-Chen.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 1rem;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: inline-block;
}

.article-container-author-label {
    color: gray;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.article-container-author-value {
    font-weight: 400;
}
.article-container-author-child {
    display: flex;
    align-items: center;

    flex-wrap: wrap;

}





/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .articles-container {
        padding: 0rem;
    }
    .articles-cards-container {
        grid-template-columns: repeat(auto-fit, 90vw);
        grid-auto-rows: 90vw;
        
    }

    .articles-top-text-container {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .articles-text-description {
        width: 100%;
        padding: 0rem;
        padding-bottom: 1rem;
    }
    
    .articles-top-image {
        width: 100%;
        height: calc(100vw * 0.5625);
    }

    .article-container-image {
        height: calc((100vw) * 0.5625);

    }

    .article-container-author-image {
        height: 50px;
        width: 50px;
    }

    .article-container-author-child { 
        font-size: 0.8rem;
    }
    
}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){
    .articles-top-image {
        height: calc(50vw * 0.5625);
    }

    .article-container-image {
        height: calc((100vw) * 0.5625);

    }


}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .articles-top-image {
        height: calc(50vw * 0.5625);
    }

}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .articles-top-container{ 
        margin-left: calc(100vw / 6);
        margin-right: calc(100vw / 6);
    }

    .article-container-content {
        margin-left: calc(100vw / 6);
        margin-right: calc(100vw / 6);
    }

}