.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    height: 100%;
    background-image: none;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 0%;

}

.footer-text {
    position: relative;
    top: 40%;
    padding: 0 1rem;
    color: white;
    text-decoration: none;
    font-size: 0.7rem;
}

@media (max-width: 648px) {
    .footer {
      min-width: 100%;
     }
   }