.table-pagination-container {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.table-pagination-back {
    margin-right: 0.5rem;
}

.table-pagination-next {
    margin-left: 0.5rem;
    cursor: pointer;
}

.table-pagination-page-container {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.table-pagination-page {
    display: grid;
    color: blue;
    cursor: pointer;
    width: 20px;
    height: 20px;
    align-content: center;
    justify-content: center;
}