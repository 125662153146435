.projectCard {
    position: relative;
    border-radius: 10px !important;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    overflow: hidden;

}

.projectCard-image {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}

.projectCard-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: gray;
    opacity: 0.4;
    z-index: 1;
}

.projectCard-text-container {
    position: absolute;
    background-color: white;
    border-radius: 10px !important;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition-timing-function: ease-in-out;
    transition: 1s;
    transform: translate(0, 80%);
}

.projectCard:hover .projectCard-text-container {
    transition-timing-function: ease-in-out;
    transition: 1s;
    transform: translate(0, 0%);
    overflow-y: auto;
}

.projectCard-text-header {
    position: absolute;
    background-color: white;
    border-radius: 10px !important;
    width: 100%;
    height: 20%;
    padding-top: 5%;
    color: black;
    font-weight: 500;
    text-align: center;
    font-size: 1rem;
    
}

.projectCard-text-content {
    position: absolute;
    width: 100%;
    top: 20%;
    color: rgb(104, 104, 104);
    background-color: white;
    padding: 1em;
}

.projectCard-label {
    font-weight: bold;
    margin-right: 0.5rem;
    overflow-wrap: break-word;
}

.projectCard-text {
    margin-right: 0.5rem;
    overflow-wrap: break-word;
}

.projectCard-status-60163187e75d838e02486555 {
    color: green;
}

.projectCard-status-601631b3e75d838e02486556 {
    color: orange;
}

.projectCard-status-dot-60163187e75d838e02486555,
.projectCard-status-dot-601631b3e75d838e02486556  {
    margin-left: 1rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    display: inline-block;

}  


.projectCard-status-dot-60163187e75d838e02486555 {
    background-color: green;

}

.projectCard-status-dot-601631b3e75d838e02486556 {
    background-color: orange;

} 


@-webkit-keyframes moveup {
    0% { position:absolute; -webkit-transform: translateY(80%); opacity: 0; }
    100% { -webkit-transform: translateY(0%); opacity: 1;}
}

@keyframes moveup {
    0% { position:absolute; -webkit-transform: translateY(80%); opacity: 0; }
    100% { -webkit-transform: translateY(0%); opacity: 1;}
}



/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .projectCard-text-header {
        font-size: 1rem;
    }

}

/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {


}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {



}