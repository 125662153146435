.contact-form-shade {
    width: 100vw;
    height: 100%;
    background: black;
    opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.contact-form {
    padding: 1rem;
    background: white;
    position: fixed;
    z-index: 1000;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.contact-form1 {
    position: relative;
    height: auto;
}

.contact-form-header {
    position: relative;
    width: 100%;
    font-weight: 500;
    color: orange;
    font-size: large;
}


.contact-form-inputbox * {
    vertical-align: middle;
}

.contact-form-labels {
    position: relative;
    font-weight: 500;
    width: 20%;
    min-width: 50px;
}

.contact-form-labels-required {
    color: red;
}
.contact-form-input {
    position: relative;
    width: 70%;
}

.contact-form-buttons {
    display: flex;
    justify-content: space-evenly;
}

.contact-form-buttons:hover {
    color: gray;
}

.contact-form-button-new-label {
    margin-left: 0.5rem;
    font-weight: bold;
    font-size: large;
}

.contact-form-button-close {
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 30px;
    width: 80px;
}

.contact-form-button-save {
    background-color: orange;
    color: white;
    border-radius: 10px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 30px;
    width: 80px;
}

.contact-form-button-close:hover,
.contact-form-button-save:hover {
    background-color: gray;
}

.contact-form-validation {
    color: red;
    font-weight: 500;
}

.contact-form-success-message {
    font-weight: 500;
}


/* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px) {
    input, label, textarea {
        display:block;
        
    }

    .contact-form-labels,
    .contact-form-input {
        width: 90%;
    }

    .contact-form {
        width: 90vw;
        max-height: 90%;
        top: 5%;
        left: 5vw;

    }
}
  
/* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px) {
    input, label, textarea {
        display:block;
        
    }

    .contact-form-labels,
    .contact-form-input {
        width: 90%;
    }

    .contact-form {
        width: 90vw;
        max-height: 90%;
        top: 5%;
        left: 5vw;

    }
}
  
/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .contact-form {
        width: 600px;
        max-height: 90%;
        top: 5%;
        left: calc(50vw - 300px);

    }
}
  
/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .contact-form {
        width: 600px;
        max-height: 90%;
        top: 5%;
        left: calc(50vw - 300px);

    }

}