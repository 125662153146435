.about-container {
    position: relative;
    min-height: 100%;
    padding-top: 1rem;
    background-image: url('fabylab-background.svg');
    background-repeat: repeat;
}

.about-glass-container {
    background: white;
    position: relative;
    min-height: 40%;
    width: 60%;
    background: linear-gradient(to right bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.3));
    border-radius: 2rem;
    margin-left: calc(50% - 30%);
    z-index: 3;
    backdrop-filter: blur(1rem);
}

.about-glass-header {
    position: relative;
    height: 10%;
    width: 100%;
    background: linear-gradient(to right bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.3));
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: gold;

}

.about-glass-content {
    position: relative;
    padding: 1rem;
    height: 50%;
    width: 100%;
}

.about-glass-content-goldenCircle {
    position: relative;
    margin-bottom: 1rem;
}

#why-back {
    fill:white;
    z-index: 4;
}

#how-back {
    fill:white;
    z-index: 3;
}

#what-back {
    fill-opacity: 0; 
    z-index: 2;
}



#why-text {
    animation: shake 6s;
    animation-delay: 1s;
    /* animation-iteration-count: infinite; */
}

#how-text {
    animation: shake 6s;
    animation-delay: 3s;
    /* animation-iteration-count: infinite; */
}

#what-text {
    animation: shake 6s;
    animation-delay: 5s;
    /* animation-iteration-count: infinite; */
}

#what-group:hover {
    cursor: pointer;
    stroke: goldenrod;
    stroke-width: 3;
    
}

#how-group:hover {
    cursor: pointer;
    stroke: orange;
    stroke-width: 3;

}

#why-group:hover {
    cursor: pointer;
    stroke: gold;
    stroke-width: 3;

}

.about-glass-content-goldenCircle-description {
    position: relative;
    min-height: 20%;
    width: 100%;
    top: 10%;
    backdrop-filter: blur(4rem);
    border-radius: 2rem;
    padding: 1rem;
    color: black;

}

.about-glass-content-goldenCircle-description-content {
    display: flex;
    flex-direction: row;
}

.description-content-icon {
    color:gray;
    margin-right: 1rem;
}

 @keyframes shake { 
    0% { transform: translate(1px, 1px) rotate(0deg); }
    2% { transform: translate(-1px, -2px) rotate(-1deg); }
    4% { transform: translate(-3px, 0px) rotate(1deg); }
    6% { transform: translate(3px, 2px) rotate(0deg); }
    8% { transform: translate(1px, -1px) rotate(1deg); }
    10% { transform: translate(-1px, 2px) rotate(-1deg); }
    12% { transform: translate(-3px, 1px) rotate(0deg); }
    14% { transform: translate(3px, 1px) rotate(-1deg); }
    16% { transform: translate(-1px, -1px) rotate(1deg); }
    18% { transform: translate(1px, 2px) rotate(0deg); }
    20% { transform: translate(1px, -2px) rotate(-1deg); } 

    80% { transform: translate(1px, 1px) rotate(0deg); }
    82% { transform: translate(-1px, -2px) rotate(-1deg); }
    84% { transform: translate(-3px, 0px) rotate(1deg); }
    86% { transform: translate(3px, 2px) rotate(0deg); }
    88% { transform: translate(1px, -1px) rotate(1deg); }
    90% { transform: translate(-1px, 2px) rotate(-1deg); }
    92% { transform: translate(-3px, 1px) rotate(0deg); }
    94% { transform: translate(3px, 1px) rotate(-1deg); }
    96% { transform: translate(-1px, -1px) rotate(1deg); }
    98% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); } 

 } 

 /* PHONES SCREEN (4 COLUMNS) */
@media (min-width: 0px) and (max-width: 640px){
    .about-glass-container {
        min-height: 100%;
        width: 90%;
        margin-left: 5%;

    }

    .about-glass-content-goldenCircle-description-content {
        display: flex;
        flex-direction: column;
    }

    .description-content-icon {
        text-align: center;
    }

}

 /* TABLETS SCREEN (8 COLUMNS) */
@media (min-width: 641px) and (max-width: 768px){
    .about-glass-container {
        min-height: 100%;
        width: 90%;
        margin-left: 5%;

    }

}

/* LAPTOPS SCREEN (8 COLUMNS) */
@media (min-width: 769px) and (max-width: 1024px) {
    .about-glass-content-goldenCircle {
        height: 70%;
        width: 70%;
        margin-left: calc(50% - 35%);
    }

}

/* LARGE SCREENS SCREEN (12 COLUMNS)*/
@media (min-width: 1025px) {
    .about-glass-content-goldenCircle {
        height: 500px;
        width: 500px;
        margin-left: calc(50% - 250px);
    }


}